.carousel-card {
  max-width: 100%;
}

.carousel-card.large-screen {
  flex: 0 0 calc(33.333% - 2rem); /* Adjust as needed */
  margin: 0 1rem;
}

.carousel-card.small-screen {
  flex: 0 0 calc(100% - 2rem);
  margin: 0 1rem;
}
